const { googleDynamicMarketingProductList } = require('./../googleDynamicMarketing');

window.google = window.google || {};

google.allpagesevents = {
  constants: {
    socialMediaButtonClass: '.tag-manager-socialmedia',
    conisualizerClass: '.tag-manager-conisualizer-btn', // TODO: Add class on consisualizer button
    footerLink: '.tag-manager-tracked-footerlink',
    navigationLevel1: '.tag-manager-tracked-navigation1',
    navigationLevel2: '.tag-manager-tracked-navigation2',
    headerNavigationPromotionArea: '.tag-manager-tracked-promotion-area',
    megaMenuContent: '.menu-item-content',
  },

  setup: function () {
    google.allpagesevents.addOnClickEventToSocialButtons();
    google.allpagesevents.addOnClickEventToConisuzlizerButtons();
    google.allpagesevents.addOnClickEventToFooter();
    google.allpagesevents.addOnClickEventToHeader();
  },

  addOnClickEventToHeader: function () {
    try {
      document.querySelectorAll(google.allpagesevents.constants.navigationLevel2).forEach((item) => {
        item.addEventListener('click', (evt) => {
          var clickedLink = evt.target.closest('a');
          var values = {
            event: 'secondaryNavigation',
            clickedurl: clickedLink.host + clickedLink.getAttribute('href'),
            labelName: clickedLink.getAttribute('data-label'),
          };
          google.tagmanager.add(values);
        });
      });

      document.querySelectorAll(google.allpagesevents.constants.headerNavigationPromotionArea).forEach((item) => {
        item.addEventListener('click', (evt) => {
          var clickedLink = evt.target.closest('a');
          var values = {
            event: 'headerNavigationpromotionArea',
            clickedurl: clickedLink.host + clickedLink.getAttribute('href'),
            headerSection: clickedLink.closest(google.allpagesevents.constants.megaMenuContent).dataset.megaMenuLabel,
            labelName: clickedLink.getAttribute('data-label'),
          };
          google.tagmanager.add(values);
        });
      });
    } catch (error) {}
  },

  addOnClickEventToFooter: function () {
    try {
      document.querySelectorAll(google.allpagesevents.constants.footerLink).forEach((item) => {
        item.addEventListener('click', (evt) => {
          var clickedLink = evt.target.closest('a');
          var values = {
            event: 'footerLinks',
            clickedurl: clickedLink.host + clickedLink.getAttribute('href'),
            labelName: clickedLink.getAttribute('data-label'),
          };
          google.tagmanager.add(values);
        });
      });
    } catch (error) {}
  },

  addOnClickEventToConisuzlizerButtons: function () {
    try {
      document.querySelectorAll(google.allpagesevents.constants.conisualizerClass).forEach((item) => {
        item.addEventListener('click', (evt) => {
          var conisualizer = evt.target.closest(google.allpagesevents.constants.conisualizerClass).dataset.conisualizer;
          google.tagmanager.events.openConisualizer(conisualizer);
        });
      });
    } catch (error) {}
  },

  addOnClickEventToSocialButtons: function () {
    try {
      document.querySelectorAll(google.allpagesevents.constants.socialMediaButtonClass).forEach((item) => {
        item.addEventListener('click', (evt) => {
          var clickedLink = evt.target.closest('a');
          var values = {
            event: 'socialMediaClick',
            socialMediaDomain: clickedLink.host,
          };
          google.tagmanager.add(values);
        });
      });
    } catch (error) {}
  },

  addLanguagePromptToDataLayer: function (country) {
    try {
      var values = google.allpagesevents.getLanguagePromptValues(country);
      google.tagmanager.add(values);
    } catch (error) {}
  },

  getLanguagePromptValues: function (country) {
    var values = {
      event: 'languagePromptVisible',
      suggestedMarket: country,
    };
    return values;
  },

  init: function () {
    try {
      google.allpagesevents.setup();

      if (
        dataLayer.find((x) => x.pageTitle)?.pageType !== 'Product page' &&
        !dataLayer.find((x) => x.event === 'view_search_results')
      )
        googleDynamicMarketingProductList('view_item_list');

      if (document.querySelector('#marketQuestion')?.classList.contains('show')) {
        var country = document.querySelectorAll('.question-box strong')[0].innerHTML;
        google.allpagesevents.addLanguagePromptToDataLayer(country);
      }
    } catch (error) {}
  },
};

if (document.readyState === 'complete' || document.readyState === 'loaded') {
  google.allpagesevents.init();
} else {
  window.addEventListener('DOMContentLoaded', () => {
    google.allpagesevents.init();
  });
}
